import { Row, Col, Button } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceCard from "../components/card/ServiceCard";
import VendorBreakdownCard from "../components/card/VendorBreakdownCard";
import columns from "../components/colums/VendorColumn";
import Content from "../components/content/Content";
import CreateVendor from "../components/modal/CreateVendor";
import CustomTable from "../components/table/CustomTable";
import {
  hideVendorModal,
  showVendorModal,
} from "../features/vendor/vendorSlice";
import {
  useFetchCardSummaryQuery,
  useFetchTransactionSummaryQuery,
  useGetVendorListQuery,
} from "../api/moremonee-api";
import { defaultDate } from "../components/utils/helper";
import moment from "moment";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Vendor = () => {
  const isModalOpen = useSelector((s) => s.vendor.showModal);
  const vendorInfo = useSelector((s) => s.vendor.vendor);
  const oneVendor = useSelector((s) => s.vendor.vendor);
  const [duration, setDuration] = useState(defaultDate);
  const dispatch = useDispatch();
  const { data: summary, isFetching: loading } =
    useFetchTransactionSummaryQuery(
      duration && duration.startTime ? duration : skipToken,
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const {
    data: vendorList,
    refetch,
    isFetching: isLoading,
  } = useGetVendorListQuery();

  const { data: cardSummary } = useFetchCardSummaryQuery(
    duration && duration.startTime ? duration : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  function onChangeVendor(vendor) {
    setDuration((prev) => ({
      startTime: prev.startTime || defaultDate.startTime,
      endTime: prev.endTime || defaultDate.endTime,
      vendor: vendor,
    }));
  }

  function durationChange(period) {
    setDuration((prev) => ({
      ...prev,
      startTime: period[0],
      endTime: period[1],
    }));
  }

  const showModal = () => {
    dispatch(showVendorModal(null));
  };

  const handleCancel = () => {
    dispatch(hideVendorModal());
  };

  const handleRefresh = () => {
    handleCancel();
    refetch();
  };

  return (
    <div>
      <Content
        pageName="Vendor"
        handleChange={onChangeVendor}
        value={[
          moment(duration.startTime) || "",
          moment(duration.endTime) || "",
        ]}
        selectField
        durationChange={durationChange}
      />

      <Row gutter={[24, 24]} className="mb-5">
        <Col xs={24} md={8}>
          <ServiceCard
            title="Total Card Payment"
            placeholder="Total Volume"
            value={summary?.totalValue}
            loading={loading}
            volume={summary?.totalVolume}
            valuePlaceHolder="Total Value"
          />
        </Col>
        <Col xs={24} md={8}>
          <ServiceCard
            title="Total Card Success"
            placeholder="Volume"
            loading={loading}
            value={summary?.successValue}
            volume={summary?.successVolume}
            valuePlaceHolder="Total Value"
          />
        </Col>
        <Col xs={24} md={8}>
          <ServiceCard
            title="Total Card Failure"
            placeholder="Volume"
            loading={loading}
            value={summary?.failureValue}
            volume={summary?.failureVolume}
            valuePlaceHolder="Total Value"
          />
        </Col>
      </Row>

      <VendorBreakdownCard cardSummary={cardSummary} title="Card Breakdown" />
      <CustomTable
        titlebar={
          <div className="flex mb-3 justify-between items-center gap-3 flex-wrap">
            <p className="font-medium text-base capitalize">
              Vendor Management
            </p>
            <div className="flex gap-4 items-center justify-end">
              <Button type="primary" onClick={showModal}>
                CREATE NEW PRICING
              </Button>
            </div>
          </div>
        }
        bordered={true}
        data={vendorList}
        columns={columns}
        loading={isLoading}
      />
      {isModalOpen && (
        <CreateVendor
          vendor={oneVendor}
          vendorInfo={vendorInfo}
          open={isModalOpen}
          handleCancel={handleCancel}
          refetch={handleRefresh}
        />
      )}
    </div>
  );
};
export default Vendor;
