import {
  Button,
  Select,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  notification,
} from "antd";
import React, { useState } from "react";
import {
  useCreateVendorSwitchMutation,
  useUpdateVendorSwitchMutation,
} from "../../api/moremonee-api";

const cardMethod = [
  {
    value: "MASTERCARD",
    label: "Master Card",
  },
  {
    value: "VISA",
    label: "Visa",
  },
  {
    value: "VERVE",
    label: "Verve",
  },
];

const processor = [
  {
    value: "GRUPP",
    label: "Grupp",
  },
  {
    value: "FLUTTERWAVE",
    label: "Flutterwave",
  },
];

const CreateVendor = ({ handleCancel, vendor, open, refetch }) => {
  const [createVendor] = useCreateVendorSwitchMutation();
  const [updateVendor] = useUpdateVendorSwitchMutation();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function onFinish(values) {
    setLoading(true);
    try {
      await createVendor(values).unwrap();
      notification["success"]({
        message: "Created Successfully",
        duration: 5,
      });
      refetch();
    } catch (err) {
      notification.error({
        message: err?.data?.message || "Something went",
        duration: 6,
      });
    }
    setLoading(false);
  }
  async function onHandleUpdate(values) {
    setIsLoading(true);
    try {
      await updateVendor({ payload: values, id: vendor.id }).unwrap();
      notification["success"]({
        message: "Updated Successfully",
        duration: 5,
      });
      refetch();
    } catch (err) {
      notification.error({
        message: err?.data?.message || "Something went",
        duration: 6,
      });
    }
    setIsLoading(false);
  }

  return (
    <Modal
      width={500}
      title="Vendor Management"
      bodyStyle={{
        padding: "14px 24px",
      }}
      className="moremoney-modal"
      footer={null}
      open={open}
      onCancel={handleCancel}
    >
      <h3 className="mb-5">Enter New Amount</h3>
      <Form
        name="normal_login"
        requiredMark={false}
        onFinish={vendor ? onHandleUpdate : onFinish}
      >
        <Row gutter={[24, 24]}>
          <Col md={12} xs={24}>
            <Form.Item
              name="from"
              initialValue={vendor?.from}
              label={<span className="text-xs">From</span>}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                className="number-bold"
                style={{ width: "100%" }}
                controls={false}
                size="large"
                placeholder="from"
                defaultValue={0}
              />
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              name="to"
              initialValue={vendor?.to}
              label={<span className="text-xs">To</span>}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                className="number-bold"
                style={{ width: "100%" }}
                controls={false}
                size="large"
                placeholder="to"
                defaultValue={0}
              />
            </Form.Item>
          </Col>
        </Row>

        <label className="mb-2 font-medium block">Select Card</label>
        <Form.Item
          name="cardType"
          initialValue={vendor?.cardType}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Select Card"
            className="w-full"
            options={cardMethod}
          />
        </Form.Item>

        <label className="mb-2 font-medium block">Select Vendor</label>

        <Form.Item
          name="vendor"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={vendor?.vendor}
        >
          <Select
            size="large"
            placeholder="Select Vendor"
            className="w-full"
            options={processor}
          />
        </Form.Item>
        <div className="pt-5 pb-10">
          <Button
            type="primary"
            size="large"
            className="w-full"
            htmlType="submit"
            loading={loading || isLoading}
          >
            Save Data
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default CreateVendor;
