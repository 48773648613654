import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutUser } from "../features/auth/authSlice";
import { notification } from "antd";

const apiUrl = process.env.REACT_APP_MIDDLEWARE_API;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders(headers, { getState }) {
    const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Content-Type", "application/json");
    return headers;
  },
});

const baseQueryRequest = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(logoutUser());
    notification.error({
      message: "Session as expired",
    });
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "moremonee-api",
  baseQuery: baseQueryRequest,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "staff/login",
        method: "POST",
        body: credentials,
      }),
    }),
    createStaffAccount: builder.mutation({
      query: (payload) => ({
        url: "staff",
        method: "POST",
        body: payload,
      }),
    }),
    getStaffs: builder.query({
      query: () => "staff",
      transformResponse: (response) => response.data,
    }),
    fetchTransationList: builder.query({
      query: (queryArgs) => ({
        url: "transaction",
        params: queryArgs,
      }),
      transformResponse: (response) => response.data,
    }),
    fetchTransactionSummary: builder.query({
      query: (queryArgs) => ({
        url: "transaction/summary",
        params: queryArgs,
      }),
      transformResponse: (response) => response.data,
    }),
    fetchCardSummary: builder.query({
      query: (queryArgs) => ({
        url: "transaction/card-summary",
        params: queryArgs,
      }),
      transformResponse: (response) => response.data,
    }),
    fetchByReference: builder.query({
      query: (reference) => `/transaction/${reference}/details`,
      transformResponse: (response) => response.data,
    }),
    getVendorList: builder.query({
      query: () => "vendor",
      transformResponse: (response) => response.data,
    }),
    createVendorSwitch: builder.mutation({
      query: (payload) => ({
        url: "vendor",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
    updateVendorSwitch: builder.mutation({
      query: ({ payload, id }) => ({
        url: `vendor/${id}`,
        method: "PUT",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useLoginMutation,
  useCreateStaffAccountMutation,
  useFetchTransationListQuery,
  useFetchTransactionSummaryQuery,
  useFetchCardSummaryQuery,
  useFetchByReferenceQuery,
  useGetStaffsQuery,
  useGetVendorListQuery,
  useCreateVendorSwitchMutation,
  useUpdateVendorSwitchMutation,
} = apiSlice;
