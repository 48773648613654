import { Button, Select, Form, Input, Modal, notification } from "antd";
import React from "react";
import { useCreateStaffAccountMutation } from "../../api/moremonee-api";

const staffType = [
  {
    value: "MEMBER",
    label: "Team Member",
  },
  {
    value: "ADMIN",
    label: "Team Lead",
  },
];

const CreateStaff = ({ handleCancel, open }) => {
  const [create, { isLoading }] = useCreateStaffAccountMutation();

  const onFinish = async (values) => {
    try {
      await create(values).unwrap();
      notification.success({
        message: "Staff Created Successfully",
        duration: 6,
      });
    } catch (err) {
      notification.error({
        message: err?.data?.message || "Something went wrong",
        duration: 6,
      });
    }
  };

  return (
    <Modal
      width={500}
      title={
        <div>
          <h3>Add New Staff</h3>
          <p className="mb-5 text-sm text-[#00000073]">
            Add new members to join the team
          </p>
        </div>
      }
      bodyStyle={{
        padding: "0 24px 24px",
      }}
      className="moremoney-modal"
      footer={null}
      open={open}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish}>
        <Form.Item
          name="name"
          required={false}
          rules={[{ required: true, message: "Enter name" }]}
        >
          <Input className="modal-input" size="large" placeholder="Full Name" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[{ required: true, message: "Enter a valid email!" }]}
        >
          <Input size="large" type="email" placeholder="E-mail" />
        </Form.Item>

        <Form.Item name="phoneNumber" required={false}>
          <Input size="large" placeholder="Phone Number" />
        </Form.Item>

        <Form.Item name="level">
          <Select
            size="large"
            placeholder="Assign Role"
            className="w-full"
            options={staffType}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password size="large" placeholder="Password" />
        </Form.Item>

        {/*
        <Form.Item name="profileId">
          <UploadImage />
        </Form.Item>*/}
        <div className="pt-5 pb-10">
          <Button
            type="primary"
            size="large"
            className="w-full"
            htmlType="submit"
            loading={isLoading}
          >
            Save Data
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default CreateStaff;
