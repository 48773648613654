import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./modules/Dashboard";
import Login from "./modules/Login";
import { Navigate, Outlet } from "react-router-dom";
import SiderBar from "./components/layout/layout";
import Transaction from "./modules/Transaction";
import Vendor from "./modules/Vendor";
import NotFound from "./modules/NotFound";
import Staff from "./modules/Staff";
import { useSelector } from "react-redux";

function App() {
  const auth = useSelector((s) => s.auth.authenticated);

  return (
    <div>
      <Routes>
        <Route path="/" element={auth ? <Outlet /> : <Navigate to="/login" />}>
          <Route path="/" element={<SiderBar />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/vendor" element={<Vendor />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </div>
  );
}

export default App;
