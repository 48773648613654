import React from "react";
import CardShell from "./CardShell";
import { Spin } from "antd";
import { FormatMoney } from "../utils/helper";

const ServiceCard = ({
  title,
  value,
  volume,
  isCard = false,
  valuePlaceHolder,
  loading,
  placeholder,
  successVolume,
  failedVolume,
}) => {
  return (
    <CardShell>
      <div className="text-sm -mt-1 mb-5 font-medium">{title}</div>
      {loading ? (
        <Spin />
      ) : (
        <div className="flex flex-col">
          <div>
            <span className="text-lg 2xl:text-2xl font-semibold">
              {FormatMoney(value)}
            </span>
            <p className="text-[#787878] text-xs">{valuePlaceHolder}</p>
          </div>
          <div>
            <span className="text-lg font-medium">{volume}</span>{" "}
            <p className="text-[#787878] text-xs">{placeholder}</p>
          </div>
        </div>
      )}
      {isCard && (
        <div className="flex gap-3 mt-1">
          <div className="flex gap-1 items-center">
            <span className="bg-[#1B9F20] h-2 w-2 rounded-xl block"></span>
            <span className="text-xs">{successVolume}</span>
          </div>

          <div className="flex gap-1 items-center">
            <span className="bg-[#C73232] h-2 w-2 rounded-xl block"></span>
            <span className="text-xs">{failedVolume}</span>
          </div>
        </div>
      )}
    </CardShell>
  );
};

export default ServiceCard;
