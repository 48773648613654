import { Descriptions, Modal, Spin } from "antd";
import React from "react";
import { useFetchByReferenceQuery } from "../../api/moremonee-api";
import { HumanFriendlyDateAndTime } from "../utils/helper";

const TransactionDetails = ({ handleCancel, open, reference }) => {
  const { data: transactionDetails = {}, isLoading } = useFetchByReferenceQuery(
    reference,
    { refetchOnMountOrArgChange: true }
  );

  return (
    <Modal
      width={924}
      bodyStyle={{
        padding: "0 24px 24px",
      }}
      title={<h3 className="font-bold">Transaction Details</h3>}
      className="moremoney-modal"
      footer={null}
      open={open}
      onCancel={handleCancel}
    >
      <p className="mb-5 text-sm text-[#00000073]">Transaction Breakdown</p>

      {isLoading ? (
        <div className="flex justify-center">
          <Spin />{" "}
        </div>
      ) : (
        <Descriptions bordered column={2}>
          {Object.keys(transactionDetails)?.map((item, i) => (
            <Descriptions.Item key={i} className="capitalize" label={item}>
              {item === "timeCreated"
                ? HumanFriendlyDateAndTime(transactionDetails[item])
                : transactionDetails[item]}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )}
    </Modal>
  );
};
export default TransactionDetails;
