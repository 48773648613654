import React from "react";

const CardShell = ({ children, className = "" }) => {
  return (
    <div
      className={`card-box-shadow rounded-lg p-5 bg-white ${
        className ?? className
      }`}
    >
      {children}
    </div>
  );
};

export default CardShell;
