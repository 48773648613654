import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StaffColumn,
  AdminStaffColumn,
} from "../components/colums/StaffColumn";
import CreateStaff from "../components/modal/CreateStaff";
import CustomTable from "../components/table/CustomTable";
import { hideStaffModal, showStaffModal } from "../features/staff/staffSlice";
import { useGetStaffsQuery } from "../api/moremonee-api";

const Staff = () => {
  const isModalOpen = useSelector((s) => s.staff.showModal);
  const { user } = useSelector((s) => s.auth);
  const { data: staffList, isLoading: loading } = useGetStaffsQuery();
  const dispatch = useDispatch();
  const isAdmin = user.level === "ADMIN";

  const showModal = () => {
    dispatch(showStaffModal(null));
  };

  const handleCancel = () => {
    dispatch(hideStaffModal());
  };

  return (
    <div>
      <div className="mb-8">
        <div>
          <h1 className="text-lg">Staff</h1>
        </div>
      </div>
      <CustomTable
        titlebar={
          <div className="flex mb-3 justify-between items-center gap-3 flex-wrap">
            <p className="font-medium text-base capitalize">
              List of Support Staffs
            </p>
            {isAdmin && (
              <div className="flex gap-4 items-center justify-end">
                <Button type="primary" onClick={showModal}>
                  Add New Staff
                </Button>
              </div>
            )}
          </div>
        }
        data={staffList}
        loading={loading}
        rowKey="email"
        columns={isAdmin ? AdminStaffColumn : StaffColumn}
      />
      {isModalOpen && (
        <CreateStaff open={isModalOpen} handleCancel={handleCancel} />
      )}
    </div>
  );
};

export default Staff;
