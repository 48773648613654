import { Row, Col, Input } from "antd";
import React, { useState } from "react";
import OverviewCard from "../components/card/OverviewCard";
import SummaryCard from "../components/card/SummaryCard";
import columns from "../components/colums/TransactionColumn";
import Content from "../components/content/Content";
import CustomTable from "../components/table/CustomTable";
import {
  useFetchTransactionSummaryQuery,
  useFetchTransationListQuery,
  useFetchCardSummaryQuery,
} from "../api/moremonee-api";
import { defaultDate } from "../components/utils/helper";
import moment from "moment";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Dashboard = () => {
  const [tnxQueryParams, setTnxQueryParams] = useState({
    page: 1,
    limit: 10,
  });
  const [duration, setDuration] = useState(defaultDate);
  const { data: transactionList, isFetching: isLoading } =
    useFetchTransationListQuery(tnxQueryParams, {
      refetchOnMountOrArgChange: true,
    });
  const { data: summary, isFetching: loading } =
    useFetchTransactionSummaryQuery(
      duration && duration.startTime ? duration : skipToken,
      {
        refetchOnMountOrArgChange: true,
      }
    );
  const { data: cardSummary } = useFetchCardSummaryQuery(
    duration && duration.startTime ? duration : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  function onChangeVendor(vendor) {
    setTnxQueryParams({
      startTime: duration.startTime || defaultDate.startTime,
      endTime: duration.endTime || defaultDate.endTime,
      vendor: vendor,
      page: 1,
    });
    setDuration((prev) => ({
      startTime: prev.startTime || defaultDate.startTime,
      endTime: prev.endTime || defaultDate.endTime,
      vendor: vendor,
    }));
  }

  function durationChange(period) {
    setDuration((prev) => ({
      ...prev,
      startTime: period[0],
      endTime: period[1],
    }));
    if (period.includes(null)) return;
    setTnxQueryParams((prev) => ({
      ...prev,
      page: 1,
      startTime: period[0],
      endTime: period[1],
      reference: "",
      terminalId: "",
    }));
  }

  function searchByTerminalId(id) {
    setTnxQueryParams({
      terminalId: id,
      page: 1,
    });
  }
  function onSearch(value) {
    setTnxQueryParams({
      reference: value,
      page: 1,
    });
  }

  return (
    <div>
      <Content
        pageName="Overview"
        handleChange={onChangeVendor}
        value={[
          moment(duration.startTime) || "",
          moment(duration.endTime) || "",
        ]}
        selectField
        durationChange={durationChange}
      />
      <Row gutter={[24, 24]} className="mb-5">
        <Col xs={24} md={8}>
          <SummaryCard
            loading={loading}
            title={"Total Transaction"}
            placeholder={"Volume"}
            value={summary?.totalValue}
            volume={summary?.totalVolume}
          />
        </Col>
        <Col xs={24} md={8}>
          <SummaryCard
            loading={loading}
            title={"Total Successful"}
            placeholder={"Volume"}
            value={summary?.successValue}
            volume={summary?.successVolume}
          />
        </Col>
        <Col xs={24} md={8}>
          <SummaryCard
            loading={loading}
            title={"Total Failed"}
            placeholder={"Volume"}
            value={summary?.failureValue}
            volume={summary?.failureVolume}
          />
        </Col>
      </Row>
      <OverviewCard cardSummary={cardSummary} title={"Transaction Breakdown"} />
      <CustomTable
        titlebar={
          <div className="flex justify-between items-center gap-3 flex-wrap">
            <p className="font-medium text-base capitalize">
              Transaction Details
            </p>
            <div className="flex gap-4 items-center justify-end">
              <div className="w-40">
                <Input.Search
                  className="w-full input-round"
                  onSearch={onSearch}
                  value={tnxQueryParams?.reference}
                  placeholder="Search Reference"
                />
              </div>
              {/*
              <div className="w-40">
                <Select
                  placeholder="Filter by type"
                  className="w-full input-round"
                  options={[
                    {
                      value: "All",
                      label: null,
                    },
                    {
                      value: "AIRTIME",
                      label: "Airtime",
                    },
                    {
                      value: "WITHDRAWAL",
                      label: "Withdrawal",
                    },
                    {
                      value: "TRANSFER",
                      label: "Transfer",
                    },
                  ]}
                />
                </div>*/}

              <div className="w-40">
                <Input.Search
                  className="w-full input-round"
                  onSearch={searchByTerminalId}
                  placeholder="Terminal ID"
                />
              </div>
              {/*
              <div className="w-40">
                <Select
                  placeholder="Filter by status"
                  className="w-full input-round"
                onChange={(value) => console.log(value) }
                  options={[
                    {
                      value: "",
                      label: "All"
                    },
                    {
                      value: "SUCCESSFUL",
                      label: "Success",
                    },
                    {
                      value: "PENDING",
                      label: "Pending",
                    },
                    {
                      value: "FAILED",
                      label: "Failed",
                    },
                  ]}
                />
                </div>*/}
            </div>
          </div>
        }
        data={transactionList?.list}
        columns={columns}
        rowKey="reference"
        loading={isLoading}
      />
    </div>
  );
};
export default Dashboard;
