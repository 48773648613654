import {
  MenuUnfoldOutlined,
  TeamOutlined,
  LogoutOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Modal } from "antd";
import businessLogo from "../../assets/svg/business.svg";
import React, { useMemo, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import TransactionIcon from "../Icon/TransactionIcon";
import VendorIcon from "../Icon/VendorIcon";
import OverviewIcon from "../Icon/OverviewIcon";
import { logoutUser } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import avatar from "../../assets/avatar.png";

const { Header, Sider, Content } = Layout;

const SiderBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleMenuSelection(value) {
    if (value.key === "logout") {
      return setIsModalOpen(true);
    }
    if (window.innerWidth <= 768) {
      setCollapsed(true);
    }
    navigate(`/${value.key}`);
  }
  function handleLogout() {
    dispatch(logoutUser());
  }

  const keyLoction = useMemo(
    () => location.pathname.replace("/", ""),
    [location.pathname]
  );

  return (
    <>
      <Layout>
        <Sider
          className={`sidebar ${!collapsed ? "open" : ""}`}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <div className="logo relative w-full max-h-24 mt-3 pb-10 p-3 flex justify-center">
            <img
              className="max-w-full max-h-full object-contain"
              src={businessLogo}
              alt="logoImage"
            />
            <button
              className="md:hidden absolute top-0 right-5"
              onClick={() => setCollapsed(true)}
            >
              <CloseOutlined
                style={{
                  fontSize: 15,
                }}
              />
            </button>
          </div>

          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[keyLoction]}
            defaultSelectedKeys={[keyLoction]}
            onClick={handleMenuSelection}
            items={[
              {
                key: "",
                icon: <OverviewIcon />,
                label: "Overview",
                className: "money-breadcrumbs",
              },
              {
                key: "transaction",
                icon: <TransactionIcon />,
                label: "Transaction",
                className: "money-breadcrumbs",
              },
              {
                key: "vendor",
                icon: <VendorIcon />,
                label: "Vendor ",
                className: "money-breadcrumbs",
              },
              {
                key: "staff",
                icon: <TeamOutlined />,
                label: "Staff",
                className: "money-breadcrumbs",
              },
              {
                key: "logout",
                icon: <LogoutOutlined />,
                label: "Logout",
                className: "money-breadcrumbs logout",
              },
            ]}
          />
        </Sider>
        <Layout className="site-layout m-auto moremoney-layout relative">
          <Header
            className="site-layout-background"
            style={{
              padding: "0 20px",
            }}
          >
            <section className="flex items-center pr-5 h-100">
              <div className="lg:hidden">
                {React.createElement(MenuUnfoldOutlined, {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                })}
              </div>
              <div className="ml-auto">
                <img src={avatar} alt="avatar-png" />
              </div>
            </section>
          </Header>
          <Content
            //className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              background: "#fff",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <Modal
        title="Logout"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <p>Are You sure you want to logout?</p>
        <div className="flex gap-4">
          <Button type="primary" ghost onClick={handleLogout}>
            Yes
          </Button>
          <Button type="primary" onClick={() => setIsModalOpen(false)}>
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default SiderBar;
