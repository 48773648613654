import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Select, DatePicker } from "antd";
import moment from "moment";

const vendors = [
  {
    value: "",
    label: "ALL",
  },
  {
    value: "GRUPP",
    label: "Grupp",
  },
  {
    value: "FLUTTERWAVE",
    label: "Flutterwave",
  },
];

const { RangePicker } = DatePicker;

const suffixSelect = (
  <div className="absolute">
    <CaretUpOutlined
      style={{
        color: "#7D7D7D",
      }}
    />{" "}
    <CaretDownOutlined
      style={{
        color: "#7D7D7D",
      }}
      className="-mt-1"
    />
  </div>
);

export default function Content({
  pageName,
  option,
  handleChange,
  durationChange,
  selectField,
  value,
}) {
  function dateChange(time) {
    let start = moment(time[0]).startOf("day").valueOf();
    let end = moment(time[1]).endOf("day").valueOf();
    durationChange([start, end]);
  }

  const onOpenChange = (open) => {
    if (open) {
      durationChange([null, null]);
    }
  };

  return (
    <div className="mb-8 flex flex-wrap justify-between gap-8">
      <div>
        <h1 className="text-lg">{pageName}</h1>
      </div>
      <div className="flex flex-wrap gap-5 items-center">
        {selectField ? (
          <Select
            placeholder="Processor"
            style={{ width: 120 }}
            onChange={handleChange}
            bordered={false}
            suffixIcon={suffixSelect}
            options={vendors}
          />
        ) : null}
        <section>
          <label className="mr-3">Choose Duration:</label>
          <RangePicker
            value={
              value && value.length && value[0].isValid() && value[1].isValid()
                ? value
                : ""
            }
            onOpenChange={onOpenChange}
            onChange={dateChange}
            allowClear={false}
          />
        </section>
      </div>
    </div>
  );
}
