import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  vendor: null,
};

const vendorReducer = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    showVendorModal: (state, { payload }) => {
      state.showModal = true;
      state.vendor = payload;
    },
    hideVendorModal: (state) => {
      state.showModal = false;
      state.vendor = null;
    },
  },
});
export default vendorReducer.reducer;
export const { showVendorModal, hideVendorModal } = vendorReducer.actions;
