import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  vendor: null,
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    showStaffModal: (state, { payload }) => {
      state.showModal = true;
      state.staff = payload;
    },
    hideStaffModal: (state) => {
      state.showModal = false;
      state.staff = null;
    },
  },
});

export const { showStaffModal, hideStaffModal } = staffSlice.actions;

export default staffSlice.reducer;
