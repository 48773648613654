import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { showVendorModal } from "../../features/vendor/vendorSlice";

export default function VendorButton({ data }) {
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(showVendorModal(data));
  };

  return (
    <div>
      <Button onClick={showModal}>View</Button>
    </div>
  );
}
