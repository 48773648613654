import { Row, Col, Input } from "antd";
import React, { useState } from "react";
import SummaryCard from "../components/card/SummaryCard";
import columns from "../components/colums/TransactionColumn";
import Content from "../components/content/Content";
import TransactionDetails from "../components/modal/TransactionDetails";
import CustomTable from "../components/table/CustomTable";
import {
  useFetchTransactionSummaryQuery,
  useFetchTransationListQuery,
} from "../api/moremonee-api";
import { defaultDate } from "../components/utils/helper";
import moment from "moment";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const Transaction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reference, setReference] = useState();
  const [tnxQueryParams, setTnxQueryParams] = useState({
    page: 1,
    limit: 10,
  });
  const [duration, setDuration] = useState(defaultDate);
  const { data: transactionList, isFetching: isLoading } =
    useFetchTransationListQuery(tnxQueryParams, {
      refetchOnMountOrArgChange: true,
    });
  const { data: summary, isFetching: loading } =
    useFetchTransactionSummaryQuery(
      duration && duration.startTime ? duration : skipToken,
      {
        refetchOnMountOrArgChange: true,
      }
    );

  function displayTransactionDetail(id) {
    setReference(id);
    setIsModalOpen(true);
  }

  function onPageChange(page) {
    setTnxQueryParams((prev) => ({
      ...prev,
      page: page,
    }));
  }

  function onChangeVendor(vendor) {
    setTnxQueryParams({
      startTime: duration.startTime || defaultDate.startTime,
      endTime: duration.endTime || defaultDate.endTime,
      vendor: vendor,
      page: 1,
    });
    setDuration((prev) => ({
      startTime: prev.startTime || defaultDate.startTime,
      endTime: prev.endTime || defaultDate.endTime,
      vendor: vendor,
    }));
  }

  function durationChange(period) {
    setDuration((prev) => ({
      ...prev,
      startTime: period[0],
      endTime: period[1],
    }));
    if (period.includes(null)) return;
    setTnxQueryParams((prev) => ({
      ...prev,
      page: 1,
      startTime: period[0],
      endTime: period[1],
      reference: "",
      terminalId: "",
    }));
  }

  function onSearch(value) {
    setTnxQueryParams({
      reference: value,
      page: 1,
    });
  }
  function searchByTerminalId(id) {
    setTnxQueryParams({
      terminalId: id,
      page: 1,
    });
  }

  return (
    <div>
      <Content
        pageName="Transaction"
        handleChange={onChangeVendor}
        value={[
          moment(duration.startTime) || "",
          moment(duration.endTime) || "",
        ]}
        selectField
        durationChange={durationChange}
      />

      <Row gutter={[24, 24]} className="mb-5">
        <Col xs={24} md={8}>
          <SummaryCard
            loading={loading}
            title={"Total Transaction"}
            placeholder={"Volume"}
            value={summary?.totalValue}
            volume={summary?.totalVolume}
          />
        </Col>
        <Col xs={24} md={8}>
          <SummaryCard
            loading={loading}
            title={"Total Successful"}
            placeholder={"Volume"}
            value={summary?.successValue}
            volume={summary?.successVolume}
          />
        </Col>
        <Col xs={24} md={8}>
          <SummaryCard
            title={"Total Failed"}
            placeholder={"Volume"}
            loading={loading}
            value={summary?.failureValue}
            volume={summary?.failureVolume}
          />
        </Col>
      </Row>
      {/*
      <TransactionBreakdown summary={summary} title="Transaction Breakdown" />*/}

      <CustomTable
        titlebar={
          <div className="flex justify-between items-center gap-3 flex-wrap">
            <p className="font-medium text-base capitalize">
              Transaction Details
            </p>
            <div className="flex gap-4 items-center justify-end">
              <div className="w-40">
                <Input.Search
                  className="w-full input-round"
                  onSearch={onSearch}
                  placeholder="Search Reference"
                />
              </div>
              <div className="w-40">
                <Input.Search
                  className="w-full input-round"
                  onSearch={searchByTerminalId}
                  placeholder="Terminal ID"
                />
              </div>
            </div>
          </div>
        }
        onRow={(record) => ({
          onClick: () => displayTransactionDetail(record.reference),
        })}
        rowClassName="cursor-pointer"
        data={transactionList?.list}
        rowKey="reference"
        loading={isLoading}
        total={transactionList?.total}
        pageChange={onPageChange}
        columns={columns}
      />
      {isModalOpen && (
        <TransactionDetails
          open={isModalOpen}
          reference={reference}
          handleCancel={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};
export default Transaction;
