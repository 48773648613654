import moment from "moment";

export const FormatMoney = (value) => {
  if (!value) return 0;
  value = Number(value);
  const splitStr = value.toString().split(".");
  const numerator = +splitStr[0];
  if (splitStr.length === 1) return "₦" + numerator.toLocaleString("en-US");
  return (
    "₦" + numerator.toLocaleString("en-US") + "." + splitStr[1].slice(0, 2)
  );
};

export const HumanFriendlyDateAndTime = (dateTime, format) => {
  if (!dateTime) return;
  let _dateTime;
  if (format) {
    _dateTime = moment(dateTime, format);
  } else {
    _dateTime = moment(dateTime);
  }
  return _dateTime.format("DD-MM-YYYY, hh:mma");
};

export const HumanFriendlyDate = (dateTime) => {
  return moment(dateTime).format("DD-MM-YYYY, HH:mma");
};

export const ReadableDate = (dateTime) => {
  return moment(dateTime).format("DD MMM YYYY, HH:mma");
};

export const capitalizeFirstLetter = (str) => {
  const result = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return result.replaceAll("_", " ");
};

export const defaultDate = {
  startTime: moment().startOf("day").valueOf(),
  endTime: moment().endOf("day").valueOf(),
};
