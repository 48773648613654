import { combineReducers } from "@reduxjs/toolkit";
import staffReducer from "../features/staff/staffSlice";
import vendorReducer from "../features/vendor/vendorSlice";
import authReducer from "../features/auth/authSlice";
import { apiSlice } from "../api/moremonee-api";

const rootReducer = combineReducers({
  auth: authReducer,
  vendor: vendorReducer,
  staff: staffReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default rootReducer;
