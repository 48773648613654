import Status from "../status/Status";
import { FormatMoney, HumanFriendlyDate } from "../utils/helper";

const TransactionColumn = [
  {
    title: "S/N",
    key: "##",
    render: (data, row, index) => <div>{index + 1}</div>,
  },
  {
    title: "Date & Time",
    key: "date",
    render: (data) => (
      <div className="whitespace-nowrap">
        {HumanFriendlyDate(data.timeCreated)}
      </div>
    ),
  },
  {
    title: "Amount",
    key: "amount",
    render: (data) => (
      <div className="whitespace-nowrap">{FormatMoney(data.amount)}</div>
    ),
  },
  {
    title: "Reference",
    key: "transactionRef",
    render: (data) => <div className="w-28 truncate"> {data.reference}</div>,
  },

  {
    title: "Status",
    key: "status",
    render: (data) => <Status status={data.status} />,
  },

  {
    title: "Terminal Serial",
    key: "terminal",
    render: (data) => (
      <div className="flex">
        <div className="capitalize font-semibold whitespace-nowrap">
          {" "}
          {data.serialNumber}
        </div>
      </div>
    ),
  },
  {
    title: "Biller ID",
    key: "biller",
    render: (data) => (
      <div className="whitespace-nowrap">{data.terminalId}</div>
    ),
  },
  {
    title: "Processor",
    key: "processor",
    dataIndex: "vendor",
  },
];

export default TransactionColumn;
