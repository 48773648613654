import React from "react";
import AuthShell from "../components/layout/Authshell";
import { Form, Input, Button, notification } from "antd";
import { useLoginMutation } from "../api/moremonee-api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/auth/authSlice";

const Login = () => {
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    try {
      const loginResponse = await login(values).unwrap();
      notification["success"]({
        message: "Login Successful",
        duration: 5,
      });
      dispatch(loginUser(loginResponse.data));
      setTimeout(() => {
        navigate("/");
      }, 500);
    } catch (err) {
      notification.error({
        message: err?.data?.message || "Login Failed",
        duration: 6,
      });
    }
  };

  return (
    <AuthShell>
      <div>
        <div>
          <h2 className="text-3xl font-medium mb-2 text-[#1E1201] font-mulish">
            Welcome back!
          </h2>
          <p className="text-[#808080] mb-16 font-mulish">
            Let's logged you back to your Account
          </p>
        </div>
        <Form name="normal_login" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input size="large" type="email" placeholder="E-mail" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>

          <div className="w-full">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="w-full"
              loading={isLoading}
            >
              Sign In
            </Button>
          </div>
        </Form>
      </div>
      <div className="absol bottom-0">
        <p className="absolute bottom-2 text-[#3B414B] 2xl:w-7/12 text-xs copyright">
          ©2020–2022 All Rights Reserved. MoreMonne® is a registered trademark
          of MoreMonne Inc.
          <span className="underline">Cookie Preferences,</span>{" "}
          <span className="underline">Privacy,</span> and{" "}
          <span className="underline">Terms.</span>
        </p>
      </div>
    </AuthShell>
  );
};

export default Login;
