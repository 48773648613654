import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  user: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
  },
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, { payload }) => {
      state.authenticated = true;
      state.user = payload;
      state.token = payload.token;
    },
    logoutUser: (state) => {
      state.authenticated = false;
      state.user = {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
      };
      state.token = null;
    },
  },
});

export const { loginUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
