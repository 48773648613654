import visa from "../../assets/image/visa.png";
import verve from "../../assets/image/verve.png";
import master_card from "../../assets/image/mastercard.png";
import { useState, useEffect, useCallback } from "react";

const CardType = ({ type }) => {
  const [icon, setIcon] = useState();

  const checkCardIcon = useCallback(() => {
    if (type.toLowerCase().includes("master")) return setIcon(master_card);
    if (type.toLowerCase().includes("verve")) return setIcon(verve);
    if (type.toLowerCase().includes("visa")) return setIcon(visa);
  }, [type]);

  useEffect(() => {
    checkCardIcon();
  }, [checkCardIcon]);

  return (
    <div className="flex gap-3 mb-5 items-center">
      <img
        className="object-contain rounded-full h-8 w-8"
        src={icon}
        alt={type}
      />
      <span className="text-sm font-medium">{type}</span>
    </div>
  );
};

export default CardType;
