import React from "react";
import CardShell from "./CardShell";
import { Spin } from "antd";
import { FormatMoney } from "../utils/helper";
//import { Skeleton } from "antd";

const SummaryCard = ({
  icon,
  title,
  value,
  volume,
  volumeChange,
  valueField,
  loading,
  placeholder,
}) => {
  return (
    <CardShell>
      <p className="text-sm mb-3 font-medium">{title}</p>
      {loading ? (
        <Spin />
      ) : (
        <div className="flex flex-col">
          <div className={`${!valueField ? "mb-4" : ""}`}>
            <span className="text-lg 2xl:text-2xl font-semibold">
              {FormatMoney(value)}
            </span>{" "}
            {valueField && (
              <p className="text-[#787878] text-xs">Total Value</p>
            )}
          </div>
          <div>
            {volume && (
              <>
                {" "}
                <span className="text-lg font-medium">{volume}</span>{" "}
                <p className="text-[#787878] text-xs">{placeholder}</p>{" "}
              </>
            )}
            {volumeChange && (
              <div className="flex items-center text-xs">
                <p className="text-xs mikro-gray">{volumeChange}% (30 days)</p>
              </div>
            )}
          </div>
        </div>
      )}
    </CardShell>
  );
};

export default SummaryCard;
