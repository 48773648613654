import React from "react";
import Icon from "@ant-design/icons";

const TransactionSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 1.25H1.25V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H18.75V17.5H2.5V1.25Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M18.75 5.625H14.375V6.875H16.6187L11.875 11.6188L9.19375 8.93125C9.13565 8.87267 9.06652 8.82617 8.99036 8.79444C8.9142 8.76271 8.83251 8.74638 8.75 8.74638C8.66749 8.74638 8.5858 8.76271 8.50964 8.79444C8.43348 8.82617 8.36435 8.87267 8.30625 8.93125L3.75 13.4938L4.63125 14.375L8.75 10.2562L11.4313 12.9437C11.4894 13.0023 11.5585 13.0488 11.6346 13.0806C11.7108 13.1123 11.7925 13.1286 11.875 13.1286C11.9575 13.1286 12.0392 13.1123 12.1154 13.0806C12.1915 13.0488 12.2606 13.0023 12.3187 12.9437L17.5 7.75625V10H18.75V5.625Z"
        fill="black"
        fillOpacity="0.45"
      />
    </svg>
  );
};

const TransactionIcon = (props) => (
  <Icon component={TransactionSvg} {...props} />
);

export default TransactionIcon;
