import { Row, Col } from "antd";
import React from "react";
import CardType from "../Icon/CardType";
import CardShell from "./CardShell";
import ServiceCard from "./ServiceCard";

const cardType = {
  MASTERCARD: "Master Card",
  VERVE: "Verve",
  VISA: "Visa",
};

export default function VendorBreakdownCard({ title, cardSummary = [] }) {
  return (
    <div className="my-10">
      <CardShell className="pb-8">
        <h3>{title}</h3>
        <div className="flex gap-3 mb-5">
          <div className="flex gap-2 items-center">
            <span className="h-2 w-2 bg-[#1B9F20]"></span>
            <span className="text-xs">Successful</span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="h-2 w-2 bg-[#C73232]"></span>
            <span className="text-xs">Failed</span>
          </div>
        </div>

        <Row gutter={[24, 24]}>
          {cardSummary.length > 0 ? (
            cardSummary.map((summary, i) => (
              <Col xs={24} md={8} key={i}>
                <ServiceCard
                  title={<CardType type={cardType[summary.cardType]} />}
                  placeholder="Volume"
                  volume={summary.volume}
                  valuePlaceHolder={"Value"}
                  value={summary.value}
                  failedVolume={summary.failedVolume}
                  successVolume={summary.successVolume}
                  isCard
                />
              </Col>
            ))
          ) : (
            <div className="text-center w-full">
              No Card Breakdown for this time period
            </div>
          )}
        </Row>
      </CardShell>
    </div>
  );
}
