import { Row, Col } from "antd";
import React from "react";
import BreakdownCard from "./BreakdownCard";

export default function OverviewCard({ cardSummary = [], metrics }) {
  return (
    <div className="my-10">
      <Row gutter={[24, 24]}>
        {cardSummary.map((card, i) => (
          <Col xs={24} md={8} key={i}>
            <BreakdownCard
              title={card.cardType}
              placeholder="Volume"
              volume={card.volume}
              value={card.value}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
