/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Pagination, Table } from "antd";
import { Link } from "react-router-dom";
//import "./table.scss";

const CustomTable = ({
  className,
  titlebar,
  link,
  loading,
  onRow,
  rowKey,
  rowSelection,
  columns,
  data,
  minWidth,
  rowClassName,
  StatusFilter,
  Search,
  showHeader,
  scroll,
  pageChange,
  pageSize,
  total,
  bordered = false,
  children,
}) => {
  let tableConfig = {
    loading: {
      spinning: loading ? loading : false,
      //indicator: <Loader />,
    },
    rowKey: rowKey || "id",
    onRow: onRow,
    columns: columns,
    dataSource: data,
    bordered: bordered,
    showHeader: showHeader,
    scroll: scroll,
    rowSelection: rowSelection || false,
    pagination: false,
  };

  return (
    <div className="bg-white px-5 pt-5 pb-5 w-full card-box-shadow rounded-lg">
      {link ? (
        <div className="flex flex-wrap justify-end mb-1">
          <Link to={link}>View all </Link>
        </div>
      ) : null}
      {titlebar}
      <div className="w-full overflow-x-auto">
        <Table
          style={{ minWidth: minWidth }}
          {...tableConfig}
          rowClassName={rowClassName}
          className={`${className} lite-thead`}
        >
          {children}
        </Table>
      </div>

      {total > 1 ? (
        <div className="flex items-center md:justify-end justify-center md:w-auto w-full mt-3 md:border-none md:pt-0 border-t pt-3">
          <Pagination
            responsive
            showLessItems
            total={total}
            pageSize={pageSize || 10}
            onChange={pageChange}
            //showSizeChanger={showSizeChanger}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomTable;
