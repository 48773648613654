import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Logo from "../../assets/svg/logo.svg";
import DefaultBG from "../../assets/Data_security.png";

const AuthShell = ({ children }) => {
  const location = useLocation();

  return (
    <div
      className="flex xl:flex-row flex-col xl:h-screen w-full min-h-screen"
      //style={{ minHeight: "800px" }}
    >
      <motion.div
        key={location.pathname}
        initial="pageInitial"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0.5,
          },
          pageAnimate: {
            opacity: 1,
          },
        }}
        className={`xl:w-1/2 w-full bg-[#95D2FF] xl:h-full relative lg:block hidden`}
      >
        <div className="px-20 pt-20">
          <img
            className="w-full h-full md:max-w-[500px] 2xl:max-w-[560px] m-auto md:max-w-auto"
            src={DefaultBG}
            alt="background"
          />
        </div>
        <div className="w-20 absolute top-5 left-7">
          <img src={Logo} alt="logo" />
        </div>

        <div className="mt-5 m-auto md:w-9/12 w-11/12">
          <p className="absolute bottom-2 mt-3 mikro-gray-2 md:w-4/5 w-11/12">
            All rights reserved ⓒ 2022
          </p>
        </div>
      </motion.div>

      <motion.div
        key={location.pathname / 2}
        initial="pageInitial"
        animate="pageAnimate"
        variants={{
          pageInitial: {
            opacity: 0,
            x: 500,
          },
          pageAnimate: {
            opacity: 1,
            x: 0,
          },
        }}
        className="xl:w-1/2 w-full lg:h-screen flex h-full relative py-10 md:pb-16 pb-20 overflow-y-auto"
      >
        <div className="px-2 2xl:w-7/12 md:w-3/4 w-10/12 m-auto">
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default AuthShell;
