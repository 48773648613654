import React from "react";

/**
 *
 * @param {String} status
 * @param {String} className
 * @param {Obj} style {style:value}
 *
 * @returns Status component
 */

const Status = ({ status, className }) => {
  return (
    <div
      className={`p-2 rounded-lg text-xs font-semibold text-center whitespace-nowrap ${
        className ?? className
      }`}
      style={
        status?.toLowerCase() === "approved" ||
        status?.toLowerCase() === "successful"
          ? {
              color: "#0A7739",
              backgroundColor: "#CEE4D7",
            }
          : status?.toLowerCase() === "payment failed" ||
            status?.toLowerCase() === "failed"
          ? {
              color: "#E2164B",
              backgroundColor: "#FDEAEF",
            }
          : status?.toLowerCase() === "pending"
          ? {
              color: "#E8A24E",
              backgroundColor: "#FDF3E0",
            }
          : status?.toLowerCase() === "refunded"
          ? { color: "#9B51E0", backgroundColor: "#9b51e01a" }
          : status?.toLowerCase().includes("issuer")
          ? {
              color: "#9f5b83",
              backgroundColor: "#FDEAEF",
            }
          : {
              color: "#1d39c4",
              backgroundColor: "#f0f5ff",
            }
      }
    >
      <span className="text-xs">{status}</span>
    </div>
  );
};

export default Status;
