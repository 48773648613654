import { HumanFriendlyDateAndTime } from "../utils/helper";

const roleType = {
  ADMIN: "Team Lead",
  MEMBER: "Team Memeber",
};

export const StaffColumn = [
  {
    title: "S/N",
    key: "##",
    render: (data, row, index) => <div>{index + 1}</div>,
  },
  {
    title: "Name",
    key: "name",
    render: (data) => (
      <div className="capitalize whitespace-nowrap">
        {`${data.firstName} ${data.lastName}`}
      </div>
    ),
  },
  {
    title: "Staff Role",
    key: "role",
    render: (data) => (
      <div className="whitespace-nowrap">{roleType[data.level]}</div>
    ),
  },

  {
    title: "Email",
    key: "email",
    render: (data) => <div className="whitespace-nowrap">{data.email}</div>,
  },

  {
    title: "Phone Number",
    key: "phonenumber",
    render: (data) => (
      <div className="whitespace-nowrap">{data.phoneNumber}</div>
    ),
  },

  {
    title: "Date Added",
    key: "date",
    render: (data) => (
      <div className="whitespace-nowrap">
        {HumanFriendlyDateAndTime(data.timeCreated)}
      </div>
    ),
  },
];

export const AdminStaffColumn = [
  {
    title: "S/N",
    key: "##",
    render: (data, row, index) => <div>{index + 1}</div>,
  },

  {
    title: "Name",
    key: "name",
    render: (data) => (
      <div className="capitalize whitespace-nowrap">{data.name}</div>
    ),
  },
  {
    title: "Staff Role",
    key: "role",
    render: (data) => (
      <div className="whitespace-nowrap">{roleType[data.level]}</div>
    ),
  },

  {
    title: "Email",
    key: "email",
    render: (data) => <div className="whitespace-nowrap">{data.email}</div>,
  },

  {
    title: "Phone Number",
    key: "phonenumber",
    render: (data) => (
      <div className="whitespace-nowrap">{data.phoneNumber}</div>
    ),
  },
  {
    title: "Date Added",
    key: "date",
    render: (data) => (
      <div className="whitespace-nowrap">
        {HumanFriendlyDateAndTime(data.timeCreated)}
      </div>
    ),
  },
];
