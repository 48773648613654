import React from "react";
import CardShell from "./CardShell";
import { Col, Row, Spin } from "antd";
import { FormatMoney } from "../utils/helper";

const BreakdownCard = ({
  title,
  value,
  volume,
  loading,
  placeholder,
  isService = false,
}) => {
  return (
    <CardShell>
      <p
        className={`font-medium ${
          isService ? "text-[#787878] text-xs -mt-1 mb-5" : "text-sm mb-3"
        }`}
      >
        {title}
      </p>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          {loading ? (
            <Spin />
          ) : (
            <div className="flex flex-col">
              <div className={`${!isService ? "mb-4" : ""}`}>
                <span className="text-lg 2xl:text-2xl font-semibold">
                  {FormatMoney(value)}
                </span>
                {isService && (
                  <p className="text-[#787878] text-xs">Total Value</p>
                )}
              </div>
              <div>
                <span className="text-lg font-medium">{volume}</span>{" "}
                <p className="text-[#787878] text-xs">{placeholder}</p>
              </div>
            </div>
          )}
          {isService && (
            <div className="flex gap-3 mt-1">
              <div className="flex gap-1 items-center">
                <span className="bg-[#1B9F20] h-2 w-2 rounded-xl block"></span>
                <span className="text-xs">5</span>
              </div>

              <div className="flex gap-1 items-center">
                <span className="bg-[#C73232] h-2 w-2 rounded-xl block"></span>
                <span className="text-xs">5</span>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </CardShell>
  );
};

export default BreakdownCard;
