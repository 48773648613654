import VendorButton from "../button/VendorButton";
import { FormatMoney } from "../utils/helper";

const VendorColumn = [
  {
    title: "S/N",
    key: "##",
    width: 30,
    render: (data, row, index) => <div>{index + 1}</div>,
  },
  {
    title: "Amount",
    key: "amount",
    render: (data) => (
      <div className="whitespace-nowrap">{`${FormatMoney(
        data.from
      )}  -  ${FormatMoney(data.to)}`}</div>
    ),
  },
  {
    title: "Card Type",
    key: "cardType",
    dataIndex: "cardType",
  },
  {
    title: "Vendor",
    key: "vendor",
    dataIndex: "vendor",
  },
  {
    title: "Action",
    key: "action",
    render: (data, record) => <VendorButton data={data} />,
  },
];

export default VendorColumn;
